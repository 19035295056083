
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          
// Library css imports
@import "scss/lib/vue-select.css";

html,
body,
#app {
  height: 100%;
}

html {
  font-size: 62.5%;
  background-color: var(--grass-color);
}

body {
  font-size: 1.6rem;
  font-family: $font-family-text;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  padding: 0;

  * {
    font-family: $font-family-text;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-title;
  font-weight: 500;
}

p,
blockquote,
a,
span {
  font-family: $font-family-text;
  font-weight: 400;
}

h1,
h1 span {
  font-weight: 500;
  font-size: $title-size-mobile;
  line-height: calc(#{$title-size-mobile});

  @include small-up {
    font-size: $title-size-desktop;
    line-height: calc(#{$title-size-desktop});
  }
}

h2,
h2 span {
  font-weight: 500;
  font-size: $subtitle-size-mobile;
  line-height: calc(#{$subtitle-size-mobile});

  @include small-up {
    font-size: $subtitle-size-desktop;
    line-height: calc(#{$subtitle-size-desktop});
  }
}

p,
p span,
a,
a span {
  font-weight: 400;
  font-size: $text-size-mobile;
  line-height: calc(#{$text-size-mobile} + #{rs(5)});

  @include small-up {
    font-size: $text-size-desktop;
    line-height: calc(#{$text-size-desktop} + #{rs(5)});
  }
}

#krpanoObject {
  z-index: 1000;
}

body > #krpanoObject {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.btn {
  text-transform: uppercase;
  position: relative;
  border-radius: 6px;
  color: $color-black;
  padding: 12px;
  text-decoration: none;
  line-height: 18px;
  top: 24px;
  transition: $basic-transition-200;

  display: flex;
  align-items: center;

  &_shadow {
    box-shadow: 0px 0px 120px 10px #00000047;
  }

  &:active {
    transform: scale(1.05);
  }
}

.btn {
  text-transform: uppercase;
  position: relative;
  border-radius: 6px;
  color: $color-black;
  padding: 12px;
  text-decoration: none;
  line-height: 18px;
  top: 24px;
  transition: $basic-transition-200;

  display: flex;
  align-items: center;

  &_shadow {
    box-shadow: 0px 0px 120px 10px #00000047;
  }

  &:active {
    transform: scale(1.05);
  }
}

::-webkit {
  &-scrollbar {
    width: 4px;

    &-track {
      border-radius: 50px;
      background: darken($color-white, 5%);
    }

    &-thumb {
      border-radius: 50px;
      background: var(--primary-color);

      &:hover {
        // Darken
        background: hsl(var(--primary-color), 100%, calc(var(50%) - 10%));
      }
    }
  }
}

/* Tinybox styling */
.tinybox {
  z-index: 8000 !important;
  background: rgba(0, 0, 0, 1) !important;

  &__content {
    &__image {
      border-radius: 4px !important;
    }

    &__control--close {
      top: 16px !important;
    }
  }
}

.gallery {
  .flicking-camera {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
  }
}

.modal {
  .flicking-camera {
    display: inline-flex !important;
  }
}

.invisible {
  opacity: 0 !important;
  pointer-events: none !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: -1 !important;
}

.mobile-hide {
  display: none;

  @include small-up {
    display: unset;
  }
}

.desktop-hide {
  display: flex !important;

  @include small-up {
    display: none !important;
  }
}
