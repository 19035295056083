.gui {
  z-index: 1001;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  * {
    pointer-events: all;
  }

  &__element {
    position: fixed;

    &--top {
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
    }

    &--bottom {
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
    }

    &--top-right {
      top: $ui-padding-mobile;
      right: $ui-padding-mobile;

      @include small-up {
        top: $ui-padding;
        right: $ui-padding;
      }
    }

    &--top-left {
      top: $ui-padding-mobile;
      left: $ui-padding-mobile;

      @include small-up {
        top: $ui-padding;
        left: $ui-padding;
      }
    }

    &--bottom-right {
      bottom: $ui-padding-mobile;
      right: $ui-padding-mobile;

      @include small-up {
        bottom: $ui-padding;
        right: $ui-padding;
      }
    }

    &--bottom-left {
      bottom: $ui-padding-mobile;
      left: $ui-padding-mobile;

      @include small-up {
        bottom: $ui-padding;
        left: $ui-padding;
      }
    }

    &--bottom-center {
      bottom: $ui-padding-mobile;
      left: 50%;
      transform: translateX(-50%);

      @include small-up {
        bottom: $ui-padding;
      }
    }

    &--top-center {
      top: $ui-padding-mobile;
      left: 50%;
      transform: translateX(-50%);

      @include small-up {
        top: $ui-padding;
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: opacity 0.1s linear;

    width: 20%;
    min-height: 55px;
    height: auto;
    width: 200px;
    opacity: 1;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: 0 0;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__title {
    font-weight: 500;
    border-radius: 0 8px 8px 0;
    word-break: break-all;
    transition: letter-spacing $basic-transition-300;
    width: 100%;
    max-width: calc(100% - (2 * #{$ui-padding-mobile}));

    @include small-up {
      width: unset;
      max-width: calc(100% - (2 * #{$ui-padding}));
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    * {
      display: flex;
      width: auto;
      word-break: normal;
      color: $color-text-inverse;
      font-weight: 500;
    }

    h1 {
      font-size: 3.6rem;
      line-height: 3.6rem;

      @include small-up {
        font-size: rs(64);
        line-height: rs(68);
      }
    }

    p {
      margin-top: 12px;
      max-width: 500px;
    }
  }

  &__top-actions {
    display: flex;
    align-items: center;
    grid-gap: 3rem;

    .phone {
      color: white;
      text-decoration: none;
      transition: opacity 0.1s linear;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__actions {
    display: none;
    align-items: center;
    grid-gap: 1rem;
    bottom: calc(#{$ui-padding} - 1.6rem);
    right: calc(#{$ui-padding} - 1.6rem);

    * {
      transition: opacity 0.1s linear;
    }

    a {
      width: 6.4rem;
      height: 6.4rem;
      text-align: center;
      position: relative;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      .label {
        display: none;
        opacity: 0;
        color: white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 40%) scale(0.9);
        transition: all 0.2s cubic-bezier(0.86, 0, 0.07, 1);
        font-size: 1.2rem;
        white-space: nowrap;

        @include small-up {
          display: inline;
        }
      }

      &:hover {
        img {
          opacity: 0.7;
        }

        .label {
          opacity: 1;
          transform: translate(-50%, 80%) scale(1);
        }
      }
    }

    @include small-up {
      display: flex;
    }
  }

  &__shadow {
    height: 100%;
    pointer-events: none;
    z-index: 1001;

    &--top {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 70%,
        rgba(0, 0, 0, 0.5) 100%
      );
    }

    &--bottom {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 70%,
        rgba(0, 0, 0, 0.5) 100%
      );
    }
  }

  .book-button {
    max-width: unset;
    margin-top: 1.6rem;
    justify-content: center;
  }
}

.side-menu {
  &__button {
    position: relative;
    padding: 1rem 0;

    &-icon {
      position: relative;
      width: 32px;
      height: 24px;

      .line {
        display: block;
        width: 100%;
        height: 4px;
        background-color: $color-text-inverse;
        right: 0;
        position: absolute;
        border-radius: 5rem;

        transition: width $basic-transition-200, opacity 0.1s linear;

        &:nth-of-type(1) {
          top: 0;
        }

        &:nth-of-type(2) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:nth-of-type(3) {
          bottom: 0;
        }
      }
    }

    .label {
      opacity: 1;
      color: white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, 80%) scale(1);
      transition: all 0.2s cubic-bezier(0.86, 0, 0.07, 1);
      font-size: 1.2rem;
      white-space: nowrap;

      @include small-up {
        transform: translate(-50%, 40%) scale(0.9);
        opacity: 0;
      }
    }

    &:hover {
      .line {
        opacity: 0.7;
      }

      .label {
        opacity: 1;
        transform: translate(-50%, 80%) scale(1);
      }

      // .line {
      //   &:first-of-type {
      //     width: 80%;
      //   }

      //   &:last-of-type {
      //     width: 60%;
      //   }
      // }
    }
  }
}
