
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          













































































.intro__overlay {
  position: absolute;
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  background-color: transparent;
  transition: opacity $basic-transition-200;
  background-color: rgba($color-black, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .fade-intro-enter-active {
    transition: opacity 0.2s linear 0.5s;
    position: absolute;
  }

  .fade-intro-leave-active {
    transition: opacity 0.2s linear;
    position: absolute;
  }

  .fade-intro-enter,
  .fade-intro-leave-to {
    opacity: 0;
  }
}

.intro,
.tutorial {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  grid-gap: 3.6rem;
  max-width: 80%;
  opacity: 1;

  @include small-up {
    max-width: 50%;
  }
}

* {
  color: $color-white;
}

h1 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 32px;

  @include small-up {
    font-size: 64px;
    line-height: 64px;
  }
}
