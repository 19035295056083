//// Fonts

/*
    Bagoss
*/

@font-face {
  font-family: "bagoss";
  src: url("/assets/fonts/BagossStandard-Regular.woff2") format("woff2"),
    url("/assets/fonts/BagossStandard-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "bagoss";
  src: url("/assets/fonts/BagossStandard-Medium.woff2") format("woff2"),
    url("/assets/fonts/BagossStandard-Medium.woff") format("woff");
  font-weight: 500;
}
