
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          














































































































:root {
  --vs-dropdown-option--active-bg: var(--secondary-color);
}

.select-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 15;

  &.show-modal-enter-active,
  &.show-modal-leave-active {
    transition: all $basic-transition-200;

    ul {
      transition: all $basic-transition-200;
      transform: translate(-50%, -50%);
    }
  }

  &.show-modal-enter,
  &.show-modal-leave-to {
    opacity: 0;

    ul {
      opacity: 0;
      transform: translate(-50%, -60%);
    }
  }

  &__close {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    z-index: 1;
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    background: transparent;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all $bounce-transition-300;
    }

    &:hover img {
      opacity: 0.7;
      transform: rotate(90deg) scale(1.2);
    }
  }

  ul {
    text-align: left;
    background: white;
    border-radius: 0.8rem;
    box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.25);
    min-height: 5rem;
    padding: $ui-padding 0 0.6rem 0;
    margin: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    height: auto;
    width: 90%;

    * {
      color: black;
      font-weight: 500;
      // text-transform: uppercase;
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 1rem 2rem;
      cursor: pointer;
      transition: all 0.07s linear;

      img.flag {
        max-width: 2rem;
        margin-right: 1.6rem;
      }

      &:hover,
      &:active,
      &.active {
        background-color: var(--secondary-color);

        span {
          // color: white;
        }
      }
    }
  }
}

.select-input-language {
  --vs-actions-padding: 0 0.5rem 0 0;
  width: 100%;

  @include small-up {
    min-width: 70%;
    width: auto;

    .vs__dropdown-toggle {
      // min-width: 40rem !important;
      // @include scaleWidth(40);
    }
  }

  .select-wrapper {
    * {
      color: $color-text;
      font-weight: 500;
    }

    .vs__dropdown-menu {
      top: auto !important;
      bottom: calc(100% + 0.6rem);
      width: 100%;
      overflow: hidden;
    }

    .vs__dropdown-toggle {
      height: 4rem;
      border-radius: 0.6rem;
      border: none;
      box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.25);
      padding: 0;
      transition: all 0.1s linear;

      &:hover {
        box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.25);
      }
    }

    .vs__search {
      display: none;
    }

    .vs__dropdown-option {
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
    }

    .vs__dropdown-menu {
      margin-top: 1rem;
      border-radius: 0.8rem;
    }

    .vs__open-indicator {
      // #C0C0C0
      filter: invert(76%) sepia(53%) saturate(0%) hue-rotate(172deg)
        brightness(93%) contrast(90%);
    }

    .vs__selected {
      height: 100%;
      width: 100%;
      padding: 0 0 0 2rem;
      margin: 0;
      display: flex;
      align-items: center;
    }

    img.flag {
      width: 2rem;
      margin-right: 1.6rem;
    }
  }
}
