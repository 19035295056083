
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          


































































































































































































































































































































































@import "@styling/gui.scss";

.krpano__wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--grass-color);

  &.clipped {
    clip-path: url(#invert);
  }

  div {
    padding: 0;
    margin: 0;
  }

  // CSS Krpano hack
  & #krpanoObject {
    & > div {
      & > div:last-child {
        & > div {
          // #hotspot__wrapper
          width: 0 !important;
          height: 0 !important;
          background-color: transparent !important;
          border: 1px solid transparent;

          &:hover,
          &.locator__touched {
            z-index: 2005 !important;
          }

          & > div {
            & > div {
              overflow: visible !important;

              & > div {
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

#krpano {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $basic-transition-200 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-splash-enter-active,
.fade-splash-leave-active {
  transition: opacity $basic-transition-1000;
}
.fade-splash-enter,
.fade-splash-leave-to {
  opacity: 0;
}

.gui {
  &__element {
    &--top-right {
      top: calc(#{$ui-padding-mobile} + 3.6rem) !important;

      @include small-up {
        top: calc(#{$ui-padding} + #{rs(36)}) !important;
      }
    }

    &--top-left {
      top: calc(#{$ui-padding-mobile} + 3.6rem) !important;

      @include small-up {
        top: calc(#{$ui-padding} + #{rs(36)}) !important;
      }
    }

    &--top-center {
      top: calc(#{$ui-padding-mobile} + 3.6rem) !important;

      @include small-up {
        top: calc(#{$ui-padding} + #{rs(36)}) !important;
      }
    }
  }

  &__preview-banner {
    display: none;
    background-color: var(--honey-color);
    text-align: center;
    padding: 1rem !important;

    p {
      color: var(--sunset-color);
      font-size: 1.4rem;
      font-weight: 500;

      @include small-up {
        font-size: rs(14);
      }
    }
  }

  &__title {
    max-width: calc(100% - calc(2 * #{$ui-padding-mobile}));
    bottom: calc(4.8rem + 2.2rem);

    @include small-up {
      max-width: 80%;
      bottom: $ui-padding;
    }

    h2 {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.345rem;
      letter-spacing: 0.12rem;
      margin-bottom: 0.4rem;

      @include small-up {
        font-size: rs(16);
        line-height: rs(18);
        letter-spacing: rs(1.6);
        margin-bottom: rs(8);
      }
    }

    h1 {
      font-size: 3.2rem;
      line-height: 3.4rem;
      font-weight: 500;

      @include small-up {
        font-size: rs(64);
        line-height: rs(68);
      }
    }
  }

  &__subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--grass-color);
    padding: $ui-padding-mobile !important;

    @include small-up {
      justify-content: unset;
      align-items: unset;
      flex-direction: column;
      width: auto;
      bottom: $ui-padding;
      right: $ui-padding;
      background-color: transparent;
    }

    * {
      text-align: left;
      color: var(--pine-color);

      @include small-up {
        text-align: right;
        color: white;
      }
    }

    h3 {
      font-size: 1.4rem;
      line-height: 1.565rem;
      margin-bottom: 00;
      font-weight: 500;

      @include small-up {
        font-size: rs(32);
        margin-bottom: rs(16);
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 1.565rem;
      font-weight: 400;

      @include small-up {
        font-size: rs(18);
        line-height: rs(20);
      }
    }
  }

  &__logo {
    width: 4rem;
    min-height: 3.4rem;
    // top: calc(2.8rem + 3.6rem) !important;
    top: calc(2.8rem) !important;

    @include small-up {
      // top: rs(76) !important;
      top: rs(56) !important;
      width: rs(217);
      height: rs(37);
    }
  }

  &__top-actions {
    // top: calc(2.5rem + 3.6rem) !important;
    top: calc(2.5rem) !important;

    @include small-up {
      // top: rs(70) !important;
      top: rs(50) !important;
    }
  }

  &__badge {
    top: 1.5rem !important;

    @include small-up {
      // top: rs(59) !important;
      top: rs(37) !important;
    }

    img {
      width: 8.8rem;
      height: 6rem;

      @include small-up {
        width: rs(108);
        height: rs(74);
      }
    }
  }
}
