
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          





































.shape-transition {
  z-index: 1000000000;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  pointer-events: none;

  * {
    pointer-events: none;
  }

  .icon {
    opacity: 0;
    object-fit: contain;
    transform-origin: center top;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  #invert {
    transform-origin: center top;
    transform: translate(calc(50vw - 50%), calc(0%)) scale(1);
  }
}
