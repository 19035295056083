//// Color Variables

$color-text: #000000;
$color-text-inverse: #ffffff;

$color-black: #000000;
$color-white: #ffffff;

//// Text Variables

$font-family-title: "bagoss", sans-serif;
$font-family-text: "bagoss", sans-serif;

$title-size-mobile: 2.9rem;
$title-size-desktop: rs(48);

$subtitle-size-mobile: 2rem;
$subtitle-size-desktop: rs(22);

$text-size-mobile: 1.6rem;
$text-size-desktop: rs(18);

//// UI
$ui-padding-mobile: 1.6rem;
$ui-padding: rs(42);

//// Animation Variables

$basic-transition-100: 100ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-200: 200ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-300: 300ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-500: 500ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-1000: 1s cubic-bezier(0.86, 0, 0.07, 1);

$bounce-transition-300: 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
$bounce-transition-500: 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
