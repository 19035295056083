
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          























































.breadcrumb {
  display: none;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: $ui-padding 0 0 0;

  li a {
    color: var(--secondary-color);
    transition: opacity 0.07s linear;

    &.no-panos {
      pointer-events: none;
      text-decoration: none;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  li ~ li:before {
    content: ">";
    color: var(--secondary-color);
    margin: 0 1rem;
    display: flex;
    align-items: center;
  }

  @include small-up {
    display: flex;
  }
}
