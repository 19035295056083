
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          






















.button {
  background: linear-gradient(
    to right,
    var(--pine-color-darker) 50%,
    var(--pine-color) 50%
  );
  background-size: 200% 110%;
  background-position: right bottom;
  border-radius: 9999px;
  box-sizing: border-box;
  color: var(--grass-color);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  transition: all $basic-transition-300;
  text-align: right;
  max-width: 25rem;
  padding: 1.6rem 3.2rem;
  font-size: 1.7rem;
  font-weight: 500;

  &:hover {
    background-position: left bottom;
  }

  @include small-up {
    max-width: rs(250);
    font-size: rs(17);
    line-height: rs(19);
    padding: rs(16) rs(32);
  }
}
