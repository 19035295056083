
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          




































































































.category {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-top: 2rem;
  transition: all 10s linear;

  &--open {
    .category__content--open {
      display: block;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    gap: 4.2rem;
    align-items: center;
    padding: 2rem 3rem 2rem 4rem !important;
    transition: all 0.1s linear;

    h3 {
      transition: all 0.1s linear;
      font-weight: 500;
    }

    .category__open {
      z-index: 1;
      display: block;
      width: 2rem;
      height: 2rem;
      background: transparent;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:hover,
    &--open {
      background: var(--primary-color);
      cursor: pointer;

      * {
        color: white;
      }

      img {
        filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(140deg)
          brightness(108%) contrast(101%);
      }
    }
  }

  .title {
    &-1 {
      font-size: 2.8rem;
      line-height: 2.8rem;
    }

    &-2 {
      font-size: 2rem;
      line-height: 2rem;
    }

    &-3 {
      font-size: 1.8rem;
      line-height: 1.8rem;
      margin-left: 2rem;
    }

    @for $i from 4 through 10 {
      &-#{$i} {
        font-size: 1.6rem;
        line-height: 1.6rem;
        margin-left: calc(1rem * #{$i});
      }
    }
  }

  &__content {
    margin-top: 1rem;
    position: relative;

    &.show-category-enter-active,
    &.show-category-leave-active {
      transition: all $basic-transition-200;
      transform: translateY(0rem);
    }

    &.show-category-enter,
    &.show-category-leave-to {
      opacity: 0;
      transform: translateY(-2rem);
    }
  }

  &__item {
    margin-top: 1rem;
    padding: 1rem 3rem 1rem 4rem !important;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      margin-left: 1.6rem;
      width: 7.2rem;
    }

    &:hover,
    &.active {
      color: var(--primary-color);
    }
  }
}
