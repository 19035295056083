
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          
























.error {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10001;
  background-color: var(--primary-color);
  display: grid;
  place-content: center;
  text-align: center;
  justify-items: center;

  &__logo {
    height: auto;
    width: 200px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  h1 {
    margin-top: 2rem;
    color: $color-text-inverse;
  }

  p {
    margin: 24px auto;
    font-weight: 500;
    color: $color-text-inverse;
  }

  .return a {
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    color: $color-text-inverse;
    text-decoration: none;
    font-size: 1.6rem;
    text-decoration: underline;
    transition: all 0.2s linear;

    &:hover {
      color: $color-text;
    }
  }
}
