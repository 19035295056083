
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          
















































































































































































$hotspot-dimension: 28px;

.hotspot {
  /*
    Position on ath/atv : krpano hack
  */
  position: absolute;
  cursor: pointer !important;
  pointer-events: all;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $hotspot-dimension;
  height: $hotspot-dimension;
  border-radius: 50%;
  background: var(--primary-color);
  display: grid;
  place-content: center;

  // Appear animation
  opacity: 1;
  width: $hotspot-dimension;
  height: $hotspot-dimension;
  transition: all 0.2s cubic-bezier(0.86, 0, 0.07, 1);
  animation: hotspot-appear $basic-transition-200;

  @keyframes hotspot-appear {
    from {
      opacity: 0;
      width: calc(#{$hotspot-dimension} - 10px);
      height: calc(#{$hotspot-dimension} - 10px);
    }
  }

  &__inner {
    &__navigation {
      width: calc(#{$hotspot-dimension} / 3);
      height: calc(#{$hotspot-dimension} / 3);
      background: $color-white;
      border-radius: 50px;
      transition: all $basic-transition-200;
      overflow: hidden;
      display: grid;
      place-content: center;

      img {
        opacity: 0;
        height: 100%;
        width: 100%;
        transition: all $basic-transition-200;
      }
    }

    &__info,
    &__small-info {
      width: $hotspot-dimension;
      height: $hotspot-dimension;
      border-radius: 50px;

      img {
        // opacity: 0;
        height: 100%;
        width: 100%;
        transition: all $basic-transition-200;
      }
    }
  }

  &__title {
    width: 200%;
    font-family: $font-family-text;
    position: absolute;
    transform: translateX(-50%);
    z-index: -1;
    left: 50%;
    color: $color-white;
    opacity: 0;
    margin-top: calc(#{$hotspot-dimension} * 3.5);
    white-space: normal;
    text-align: center;
    pointer-events: none;
    text-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.5);
  }

  &__content {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    height: $hotspot-dimension;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 20px 0 50px;
    border-radius: 50px;
    opacity: 0;
    overflow: hidden;
    transition: all $basic-transition-200;

    span {
      opacity: 0;
      transition: all $basic-transition-1000;
    }

    &__bg {
      background: var(--primary-color);
      width: 0%;
      height: 100%;
      left: 0;
      transition: all $basic-transition-500;
      position: absolute;
      z-index: -1;
    }

    &--small-info {
      padding: 12px;
      flex-direction: column;
      // min-height: 200px;
      height: auto;
      border-radius: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: -1;
      border: 4px solid $color-white;
      background-color: var(--primary-color);
      opacity: 0;
      transition: all $basic-transition-200;

      display: flex;
      align-items: flex-start !important;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 250px;
      text-align: left;

      span {
        font-weight: 500;
        margin-bottom: 12px;
        font-size: 1.6rem;
        white-space: break-spaces;
        transition: all $basic-transition-500;
      }

      p {
        font-weight: 400;
        opacity: 0;
        font-size: 1.4rem;
        transition: all $basic-transition-500;
        white-space: break-spaces;
      }
    }

    * {
      color: $color-text-inverse;
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__outer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba($color-white, 0.5);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    width: 36px;
    height: 36px;
    z-index: -2;
    border-radius: 50%;

    // Appear animation
    opacity: 1;
    width: 36px;
    height: 36px;
    transition: all 0.2s cubic-bezier(0.86, 0, 0.07, 1);
    animation: hotspot-outer-appear $basic-transition-200;

    @keyframes hotspot-outer-appear {
      from {
        opacity: 0;
        width: 10px;
        height: 10px;
      }
    }
  }

  &:hover,
  &.touched {
    width: calc(#{$hotspot-dimension} * 3.1);
    height: calc(#{$hotspot-dimension} * 3.1);

    & + .hotspot__outer {
      width: 96px;
      height: 96px;
    }

    .hotspot__inner {
      width: calc(#{$hotspot-dimension} * 2.7);
      height: calc(#{$hotspot-dimension} * 2.7);

      img {
        opacity: 1;
      }
    }

    .hotspot__title {
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.86, 0, 0.07, 1) 0.25s;
    }

    .hotspot__content {
      opacity: 1;
      pointer-events: all;

      span,
      p {
        opacity: 1;
      }

      &__bg {
        width: 100%;
      }
    }
  }
}
