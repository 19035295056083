
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          














































































.controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: 5.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none !important;

  @include small-up {
    height: rs(76);
  }

  .control {
    position: relative;
    height: 100%;
    display: grid;
    place-items: center;
    pointer-events: all;
    background: var(--grass-color);
    cursor: pointer;
    transition: width ease 0.15s, background-color ease 0.15s;
    width: 5.7rem;

    @include small-up {
      width: rs(76);
    }

    svg {
      object-fit: contain;
      position: absolute;
      width: 3.1rem;
      height: 3.1rem;

      path {
        stroke: var(--pine-color);
        transition: stroke ease 0.15s;
      }

      @include small-up {
        width: rs(41);
        height: rs(41);
      }
    }

    &--prev {
      border-radius: 0 9999px 9999px 0;

      svg {
        transform: rotate(180deg);
        right: 1.8rem;

        @include small-up {
          right: rs(24);
        }
      }
    }

    &--next {
      border-radius: 9999px 0 0 9999px;

      svg {
        left: 1.8rem;

        @include small-up {
          left: rs(24);
        }
      }
    }

    @include small-up {
      &:hover {
        width: rs(100);
        background: var(--pine-color);

        path {
          stroke: var(--grass-color);
        }
      }
    }
  }
}
