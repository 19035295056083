// https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862#.adpufyefv

$breakpointMap: (
  "small-up": "799px",
);

@mixin small-up {
  @media (min-width: map-get($breakpointMap, "small-up")) {
    @content;
  }
}

@mixin mediaquery($key) {
  @if (map-has-key($breakpointMap, $key)) {
    @media (min-width: map-get($breakpointMap, $key)) {
      @content;
    }
  } @else {
    @error "Breakpoint '#{$key}' doesn't exist";
  }
}
