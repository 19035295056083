
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          






















.button {
  max-width: 25rem;
  background: linear-gradient(
    to right,
    var(--secondary-color-darker) 50%,
    var(--secondary-color) 50%
  );
  background-size: 200% 110%;
  background-position: right bottom;
  border-radius: 50rem;
  box-sizing: border-box;
  color: var(--primary-color);
  cursor: pointer;
  padding: 1.6rem 3.2rem;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  overflow: hidden;

  transition: all $basic-transition-300;

  &:hover {
    background-position: left bottom;
  }
}
